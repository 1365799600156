import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonVariant, Input, Typography } from '@objectedge/ziyou-storefront-ds';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SendResetPasswordDocument } from '~/operations';
import { useAlertsContext } from '~/utils/useAlerts';
import Yup from '~/utils/yup';
import styles from './ResetForm.module.scss';

type ResetFormData = {
  email: string;
};

const schema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo é obrigatório'),
});

export const ResetForm = () => {
  const Router = useRouter();
  const { actions: alertsActions } = useAlertsContext();
  const [sendResetPassword] = useMutation(SendResetPasswordDocument);
  const { register, handleSubmit, errors } = useForm<ResetFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(async (formValues: ResetFormData) => {
    try {
      await sendResetPassword({
        variables: {
          email: formValues.email,
          tenant: 'store',
        },
      });

      Router.push('/login/reset/success');
    } catch (err) {
      alertsActions.addDangerAlert('Email informado está incorreto ou não foi cadastrado');
    }
  }, []);

  return (
    <div className={styles['main']}>
      <Typography className={styles['title']} variant="h1" weight="bold">
        Problemas para entrar?
      </Typography>
      <Typography className={styles['subtitle']} variant="body-text-1">
        Iremos te enviar um link de redefinição de senha para o e-mail informado no seu cadastro.
      </Typography>
      <Input
        label="Email"
        name="email"
        ref={register}
        status={errors.email ? 'danger' : 'default'}
        helperText={errors.email?.message ?? ''}
        error={!!errors.email?.message}
      />
      <div className={styles['buttons-container']}>
        <Button className={styles['button']} onClick={handleSubmit(onSubmit)}>
          enviar link
        </Button>
        <Button outline variant={ButtonVariant.DARK} onClick={() => Router.push('/login')}>
          voltar
        </Button>
      </div>
    </div>
  );
};

export default ResetForm;
