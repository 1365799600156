import { Typography, Button, ButtonVariant, ButtonSize } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useRouter } from 'next/router';

interface props {
  onclick: () => void;
}

export const CookiePolicyNotification = ({ onclick }: props) => {
  const router = useRouter();
  return (
    <div
      className={clsx(
        styles['cookie-notification-container'],
        router.asPath.includes('/produto/') && styles['cookie-notification-positionfix']
      )}
    >
      <div className={styles['cookie-notification-body']}>
        <Typography variant="body-text-2" className={styles['cookie-notification-message']}>
          Guardamos estatísticas de visitas para melhorar sua experiência. Saiba mais{' '}
          <Link href="/politica-de-privacidade">
            <a>Aqui</a>
          </Link>
        </Typography>
        <Button
          outline
          variant={ButtonVariant.DARK}
          size={ButtonSize.SM}
          onClick={onclick}
          className={styles['cookie-notification-button']}
        >
          Concordar e Fechar
        </Button>
      </div>
    </div>
  );
};

export default CookiePolicyNotification;
