import clsx from 'clsx';
import { useState } from 'react';
import CookiePolicyNotification from '~/components/cookie-policy-notification';
import { Footer, Header } from '~/components/structure';
import { acceptedCookiesPolicy } from '~/state/cookiePolicy';
import styles from './MainLayout.module.scss';

export interface MainLayoutProps {
  compact?: boolean;
  fluid?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, compact, fluid }) => {
  const [showCookiePolicy, setShowCookiePolicy] = useState<boolean>(!acceptedCookiesPolicy());

  return (
    <div className={styles.container}>
      <header>
        <Header compact={compact} />
      </header>
      <main className={clsx(styles.main, fluid ? 'container-fluid p-0' : 'container-md')}>{children}</main>
      {showCookiePolicy && (
        <CookiePolicyNotification
          onclick={() => {
            setShowCookiePolicy(false);
            acceptedCookiesPolicy(true);
          }}
        />
      )}
      <footer>
        <Footer compact={compact} />
      </footer>
    </div>
  );
};

export default MainLayout;
